import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import React from "react";

import { Button } from "~src/designSystem/atoms/Button";
import { TwoPartCard } from "~src/designSystem/atoms/TwoPartCard";
import { Flexbox } from "~src/designSystem/layout/Flexbox";

import { ConnectionStatusBadge } from "./ConnectionStatusBadge";
import { CircleCheck } from "./icons/CircleCheck";
import { CirclePlus } from "./icons/CirclePlus";
import { CircleWarning } from "./icons/CircleWarning";

type IConnectDataSourceCardProps = {
  headingText: string;
  bodyText: string;
  needsInitialLink: boolean;
  needsAdditionalLink: boolean;
  needsRelink: boolean;
  addSourceAction: () => void;
  connectedBadgeLabel: string;
  disconnectedBadgeLabel?: string;
};

const CARD_NO_RADIUS_BREAKPOINT = "425px";

export const ConnectDataSourceCard: React.FC<IConnectDataSourceCardProps> = (props) => {
  const {
    headingText,
    bodyText,
    addSourceAction,
    connectedBadgeLabel,
    disconnectedBadgeLabel,
    needsAdditionalLink,
    needsInitialLink,
    needsRelink,
  } = props;

  const router = useRouter();

  const statusIcon = (() => {
    if (needsAdditionalLink || needsRelink) {
      return <CircleWarning />;
    }
    if (needsInitialLink) {
      return <CirclePlus />;
    }
    return <CircleCheck />;
  })();

  return (
    <TwoPartCardWrapper
      isActive={needsInitialLink || needsAdditionalLink || needsRelink}
      justifyContent="space-between"
      maxWidth="480px"
    >
      <Flexbox
        row
        alignItems="flex-start"
        paddingTop="16px"
        paddingX="16px"
        paddingBottom="8px"
        marginY="8px"
        marginX="0px"
      >
        <Flexbox marginRight="32px">
          <Flexbox row>
            <span style={{ marginRight: "4px", marginLeft: "-2px" }}>{statusIcon}</span>
            <Header isActive={needsInitialLink || needsAdditionalLink || needsRelink}>
              {headingText}
            </Header>
          </Flexbox>
          <Body>{bodyText}</Body>
        </Flexbox>
        <Flexbox shrink={0}>
          <Button
            size="small"
            kind={needsInitialLink || needsAdditionalLink ? "primary" : "secondary"}
            onClick={addSourceAction}
          >
            Add source
          </Button>
        </Flexbox>
      </Flexbox>
      <Flexbox row gap="6px" paddingX="20px" paddingY="8px">
        <ConnectionStatusBadge label={connectedBadgeLabel} />
        {disconnectedBadgeLabel !== undefined && (
          <ConnectionStatusBadge
            label={disconnectedBadgeLabel}
            variant="warning"
            onClick={() => router.push("/settings/data-sources")}
          />
        )}
      </Flexbox>
    </TwoPartCardWrapper>
  );
};

const TwoPartCardWrapper = styled(TwoPartCard)`
  @media (max-width: ${CARD_NO_RADIUS_BREAKPOINT}) {
    border-radius: 0;
  }
`;

const Header = styled.span<{ isActive?: boolean }>`
  ${(props) => {
    if (props.isActive === true) {
      return css`
        ${props.theme.textStyles.Bold.Body300};
        color: ${props.theme.components.Card.Todo.Header.activeText};
      `;
    }

    return css`
      ${props.theme.textStyles.Regular.Body300}
      color: ${props.theme.components.Card.Todo.Header.text}
    `;
  }}
`;

const Body = styled.p`
  ${(props) => props.theme.textStyles.Regular.Body100};
  color: ${(props) => props.theme.components.Card.Todo.Body.text};
  margin-bottom: 0;
`;
