/** @jsxImportSource @emotion/react */
import { css, Theme } from "@emotion/react";
import React from "react";
import { FiChevronLeft } from "react-icons/fi";

import { Button } from "~src/designSystem/atoms/Button";
import { Text } from "~src/designSystem/atoms/Text";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { Head } from "~src/designSystem/sortLater/Head";
import { useUser } from "~src/shared/auth/useUser";
import { useNotNullContext } from "~src/shared/helpers/useNotNullContext";
import { ProposalsContext } from "~src/vendor/inbox//contexts/ProposalsContext";

import { IInboxStep, useInboxState } from "../state";
import { CoreMetrics } from "./CoreMetrics";

export const InboxInfo: React.FC = () => {
  const user = useUser();

  const wizardStep = useInboxState((state) => state.wizardStep);
  const setWizardStep = useInboxState((state) => state.setWizardStep);
  const { checkable, data } = useNotNullContext(ProposalsContext);

  const onGoBackButton = () => {
    if (wizardStep === IInboxStep.COMPLETE) {
      checkable.reset();
      // First reset then refresh proposals
      data.reset().then(() => data.loadAllRows());
    }
    setWizardStep(IInboxStep.INBOX);
  };

  return (
    <>
      {wizardStep === IInboxStep.INBOX && <Head title="Available to Pipe | Pipe" />}
      {wizardStep === IInboxStep.REVIEW && <Head title="Review Payout | Pipe" />}
      {wizardStep === IInboxStep.COMPLETE && <Head title="Payout Successful | Pipe" />}
      <Flexbox gridColumn="span 3">
        {(() => {
          switch (wizardStep) {
            case IInboxStep.INBOX:
              return (
                <Flexbox
                  row
                  alignItems="flex-start"
                  padBottom
                  css={(theme: Theme) => css`
                    border-bottom: 1px solid ${theme.components.Divider.default};
                  `}
                >
                  <Text
                    className="trade-header"
                    type="light"
                    size="800"
                    as="h1"
                    color="contentEmphasis"
                    css={{
                      marginRight: "70px",
                    }}
                  >
                    Trade
                  </Text>
                  <CoreMetrics key={wizardStep} vendorPublicIDOverride={user.vendorPublicID} />
                </Flexbox>
              );
            case IInboxStep.REVIEW:
            case IInboxStep.COMPLETE:
              return (
                <Flexbox row alignItems="center">
                  <Button
                    css={css`
                      background-color: transparent;
                      padding: 14px 14px 8px 0;
                      &:hover {
                        background-color: transparent;
                      }
                    `}
                    onClick={onGoBackButton}
                  >
                    <FiChevronLeft color="#fff" size={20} />
                  </Button>
                  <Text color="contentEmphasis">Available To Pipe</Text>
                </Flexbox>
              );
          }
        })()}
      </Flexbox>
    </>
  );
};
