import { useTheme } from "@emotion/react";
import React from "react";

import { Text } from "~src/designSystem/atoms/Text";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { ICountryCode } from "~src/shared/types";

import { ContactSupport } from "./ContactSupport";

export type IProps = {
  country?: ICountryCode;
};

export const Declined: React.FC<IProps> = ({ country }) => {
  const theme = useTheme();

  return (
    <Flexbox gap="24px" width="400px" marginTop="64px">
      <Text as="h1" size="600">
        No bids have come in for your contracts
      </Text>
      <Text
        as="p"
        css={{
          color: theme.components.Declined.descriptionTextColor,
        }}
      >
        We'll monitor your account and let you know when there is demand for your contracts.
      </Text>
      <Spacer y="6" />
      <ContactSupport country={country as ICountryCode} questionText="Need help?" />
    </Flexbox>
  );
};
