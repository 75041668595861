import React from "react";

import { Checkbox } from "~src/designSystem/atoms/Checkbox";
import { Link } from "~src/designSystem/atoms/Link";
import { OldHorizontalRuleDontUse } from "~src/designSystem/deprecated/HorizontalRule";
import { OldSpanUseTextInstead } from "~src/designSystem/deprecated/Span";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { useUser } from "~src/shared/auth/useUser";
import { useInboxState } from "~src/vendor/inbox/state";

export const AgreementCheckBox: React.FC = () => {
  const user = useUser();

  const isTermsChecked = useInboxState((state) => state.isTermsChecked);
  const setIsTermsChecked = useInboxState((state) => state.setIsTermsChecked);

  let contents;
  if (user.vendor.country === "GB") {
    contents = (
      <>
        I agree to the terms of the{" "}
        <Link
          newTab
          href="https://storage.googleapis.com/production-assets/pipe_agreement_uk_2022_02_21.pdf"
        >
          Pipe Agreement
        </Link>{" "}
        for the contracts I’ve selected.
      </>
    );
  } else {
    contents = (
      <>
        I agree to the terms of the{" "}
        <Link
          newTab
          href="https://storage.googleapis.com/production-assets/Pipe-Agreement-11_12_21.pdf"
        >
          Pipe Agreement
        </Link>{" "}
        for the contracts I’ve selected and agree to the{" "}
        <Link
          newTab
          href="https://storage.googleapis.com/production-assets/pipe_ach_agreement_2020_02_27.pdf"
        >
          automatic ACH agreement
        </Link>
        .
      </>
    );
  }

  return (
    <Flexbox paddingTop="20px">
      <Flexbox>
        <Flexbox paddingBottom="20px">
          <Checkbox
            id="terms1"
            checked={isTermsChecked}
            onChange={(e) => {
              setIsTermsChecked(e.target.checked);
            }}
          >
            <OldSpanUseTextInstead color="subtitle">{contents}</OldSpanUseTextInstead>
          </Checkbox>
        </Flexbox>
        <OldHorizontalRuleDontUse color="box" />
      </Flexbox>
    </Flexbox>
  );
};
