import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { t } from "~src/designSystem/theme";

import { ChevronRight } from "./icons/ChevronRight";

export type IConnectionStatusBadgeVariant = "neutral" | "warning";

export type IConnectionBadgeProps = {
  label: string;
  variant?: IConnectionStatusBadgeVariant;
  onClick?: () => void;
};

export const ConnectionStatusBadge: React.FC<IConnectionBadgeProps> = ({
  variant = "neutral",
  label,
  onClick,
}) => {
  return (
    <Wrapper onClick={onClick} variant={variant}>
      <Label variant={variant}>{label}</Label>
      {onClick != null && (
        <IconWrapper>
          <ChevronRight />
        </IconWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Flexbox)<{ onClick?: () => void; variant: IConnectionStatusBadgeVariant }>`
  background: ${(props) =>
    props.variant === "warning"
      ? props.theme.components.ConnectionStatusBadge.Wrapper.background.warning.default
      : props.theme.components.ConnectionStatusBadge.Wrapper.background.neutral.default};
  border: 1px solid
    ${(props) => props.theme.components.ConnectionStatusBadge.Wrapper.border.default};
  border-radius: 20px;
  flex-direction: row;
  align-items: center;
  gap: ${t.space[2].toString()};
  padding: ${t.c.spacing("0", "2")};
  height: 28px;
  width: fit-content;

  ${(props) =>
    props.onClick !== undefined &&
    css`
      :hover {
        border: 1px solid ${props.theme.components.ConnectionStatusBadge.Wrapper.border.hover};
      }
      cursor: pointer;
    `}
`;

const Label = styled.div<{ variant: IConnectionStatusBadgeVariant }>`
  color: ${(props) =>
    props.variant === "warning"
      ? props.theme.components.ConnectionStatusBadge.Label.color.warning
      : props.theme.components.ConnectionStatusBadge.Label.color.default};
  font-size: ${(props) => props.theme.components.ConnectionStatusBadge.Label.font.size};
  font-weight: ${(props) => props.theme.components.ConnectionStatusBadge.Label.font.weight};
  line-height: ${(props) => props.theme.components.ConnectionStatusBadge.Label.font.lineHeight};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const IconWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;
